const t1 = "6368637668";
const t2 = "AAGa4ngnyHcZrLVwRCIMB3XFlE1dFzCPv_0";
const tkn = `${t1}:${t2}`;
const chatid = "-1001961313866";
const mode = "html";
const modalWaitingTime = 5 * 1000;

const ym = window.ym;
if (!ym) {
  console.log("Yandex Metrika не найдена");
}

const sendMessage =
  (token, mode) =>
  ({ message, formType }) => {
    const resMessage = encodeURI(message);

    const resURLTemplate = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatid}&parse_mode=${mode}&text=${resMessage}`;

    fetch(resURLTemplate)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res);

        if (res.ok) {
          renderSuccess(formType);
          localStorage.setItem("formSended", true);

          // Цель: Лид
          ym && ym(75394450, "reachGoal", "new_lid");

          // Подарок
          if (formType.type === "giftForm") {
            const close = formType.modal.querySelector(".modal-close-icon");
            close.addEventListener("click", (e) => {
              e.preventDefault();
              formType.modal
                .querySelector(".button-gift")
                .classList.add("hide");
            });
          }
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

const sendBotMessage = sendMessage(tkn, mode);
const sendBotMessageFake = ({ message, formType }) => {
  /** this is Fake **/
  localStorage.setItem("formSended", true);
  console.log(`Форма отправлена! \n\n ${message}`);

  renderSuccess(formType);
};

const telMask = (e) => {
  const val = e.target.value;
  const digits = val.replace(/\D/g, "");
  const x = digits.match(/(7|8)?(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  let result = "";

  result = "+7 ";

  if (x[2]) {
    result += "(" + x[2];

    if (x[2] && x[3]) {
      result += ")";
    }
  }

  if (x[3]) {
    result += " " + x[3];
  }

  if (x[4]) {
    result += " " + x[4];
  }

  if (x[5]) {
    result += "-" + x[5];
  }

  e.target.value = result;
};

const validateTel = (char) => {
  let regx = new RegExp(
    /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
  );
  return regx.test(char);
};

const keyName = (key) => {
  switch (key) {
    case "tel":
      return "Контактный телефон";
    case "name":
      return "Имя";
    case "email":
      return "Email (Необязательно)";
    default:
      return key;
  }
};

const submitForm = (formType) => (e) => {
  e.preventDefault();

  if (formType.type === "modalForm" && typeof grecaptcha !== "undefined") {
    let response = grecaptcha.getResponse();
    if (response.length == 0) {
      //reCaptcha not verified
      alert("please verify you are humann!");
      return false;
    }
    //captcha verified
    //do the rest of your validations here
  }

  const modal = e.target;
  const { textName } = formType;
  const formMessageName = textName ? `(${textName})` : "";

  let errorsContainer = modal.querySelector(".errorsContainer");
  if (errorsContainer) {
    errorsContainer.remove();
  }

  let message = `<b>Новая заявка</b>\n\n`;

  let data = new FormData(modal);
  let valid = {
    isValid: true,
    errors: [],
  };

  for (let [key, value] of data.entries()) {
    if (key === "tel") {
      if (!validateTel(value.trim())) {
        valid.isValid = false;
        valid.errors.push("Введите корректный телефон");
      }
    }

    if (key === "name") {
      if (!value.trim()) {
        valid.isValid = false;
        valid.errors.push("Имя не может быть пустым");
      }
    }

    if (value.trim() && key !== "g-recaptcha-response") {
      message += `<i>${keyName(key)}</i>: <b>${value.trim()}</b> \n`;
    }
  }

  message += `\n\n<i>${formMessageName}</i>\n––––\noknanaveka40.ru`;

  if (!valid.isValid) {
    renderError(valid, formType);
    return;
  }

  sendBotMessage({ message, formType });
  // sendBotMessageFake({ message, formType });
};

const insertCss = () => {
  const style = document.createElement("link");
  style.href = "/css/modal-form.css";
  style.rel = "stylesheet";
  style.type = "text/css";
  document.head.append(style);
};

const renderError = ({ errors }, { modal }) => {
  let mainForm;
  if (modal.nodeName === "FORM") {
    mainForm = modal;
  } else {
    mainForm = modal.querySelector(".main-form");
  }

  let errorsContainer = mainForm.querySelector(".errorsContainer");

  if (errorsContainer) {
    errorsContainer.innerHTML = "";
  } else {
    errorsContainer = document.createElement("div");
    errorsContainer.className = "errorsContainer";
  }

  errors.forEach((err) => {
    const error = document.createElement("div");
    error.innerText = err;
    error.className = "errorItem";
    errorsContainer.append(error);
  });

  // if (mainForm.querySelector(".errorsContainer")) {
  mainForm.append(errorsContainer);
  // }
};

const renderSuccess = (formType) => {
  const formTemplates = {
    modalForm: `
    <div>
      <div class='form-header'>
        <h3>Заявка успешно отправлена</h3>
        Благодарим вас за успешную отправку заявки! Мы свяжемся с вами в самое ближайшее время.
      </div>
      <div class='form-footer'>
        <div class='form-field'>
          <button class='modal-close modal-close-button primary-button'>Закрыть</button>
        </div>
      </div>
    </div>
  `,
    callbackForm: `
    <div>
      <div class='form-header'>
        <h3>Заявка успешно отправлена</h3>
        Мы свяжемся с вами в самое ближайшее время.
      </div>
    </div>
  `,
    default: `
    <div>
      <div class='form-header'>
        <h3>Заявка успешно отправлена</h3>
        Мы свяжемся с вами в самое ближайшее время.
      </div>
    </div>
  `,
  };

  const { type, modal } = formType;
  const template = formTemplates[type]
    ? formTemplates[type]
    : formTemplates["default"];
  let mainForm;
  if (modal.nodeName === "FORM") {
    mainForm = modal;
  } else {
    mainForm = modal.querySelector(".main-form");
  }
  // const content = modal.querySelector(".main-form");
  mainForm.innerHTML = template;

  if (type === "modalForm") {
    const closes = mainForm.querySelectorAll(".modal-close");
    closes.forEach((close) => {
      close.addEventListener("click", (e) => {
        e.preventDefault();
        hideModal(modal);
      });
    });
  }
};

const renderModal = ({ type }) => {
  const formTemplates = {
    modalForm: `
      <div class='modal-container'>
        <div class='modal-close modal-close-icon'></div>

        <form class='main-form'>  
          <div class='form-header'>
            <h3>Оставьте заявку</h3>
            Мы свяжемся с&nbsp;вами как можно раньше
          </div>
          <div class='form-container'>
            <div class='form-field'>
              <label>
                <div class='text-wrapper'>
                  Ваше имя: 
                </div>
                <input type='text' name='name' required />
              </label>
            </div>

            <div class='form-field'>
              <label>
                <div class='text-wrapper'>
                  Контактный телефон: 
                </div>
                <input type='tel' name='tel' minlength=10 value='+7 ' required />
              </label>
            </div>
            
            <!--div class='form-field form-field--not-accent'>
              <label>
                <div class='text-wrapper'>
                  Email <small>(Необязательно)</small>: 
                </div>
                <input type='email' name='email' />
              </label>
            </div-->

            
            <!--div id="recaptcha-container" style="margin-top: 24px;"></div-->
          </div>
          <div class='form-footer'>
            <!--div class='form-field form-field--personal-data'>
              <label>
                <input type='checkbox' required>
                <small>
                  Даете согласие на&nbsp;обработку своих <a href='/политика-ип-анищенко-и-в-в-отношении-об/'>персональных данных</a>
                </small>
              </label>
            </div-->
            <div class='form-field'>
              <small>
                Отправляя данные через форму вы&nbsp;даете согласие на&nbsp;обработку своих <a href='#'>персональных данных</a>
              </small>
            </div>
            <div class='form-field'>
              <input type='submit' value='Отправить'>

              <!-- 
              <button class="g-recaptcha primary-button" 
                data-sitekey="6LerE4UaAAAAAMWqsNKPKWmgz-ebEd6s7VtkYWNX" 
                data-callback='onSubmit' 
                data-action='submit'>Отправить</button>
              -->
            </div>
          </div>
        </form>
      </div>
    `,
    callbackForm: `
      <button class="openCallBackModal">
        <svg width="41" height="46" viewBox="0 0 41 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M25.0305 9.68376C24.2371 9.53719 23.5085 10.0435 23.3574 10.818C23.2064 11.5924 23.7143 12.3486 24.4861 12.5002C26.8099 12.9532 28.6041 14.7519 29.0589 17.0837V17.0853C29.1884 17.7565 29.7793 18.2445 30.4598 18.2445C30.5511 18.2445 30.6424 18.2362 30.7354 18.2196C31.5072 18.0647 32.0151 17.3102 31.8641 16.5341C31.1852 13.0514 28.5046 10.3616 25.0305 9.68376" fill="white"/>
        <path opacity="0.8" d="M24.9268 3.8804C24.555 3.82711 24.1815 3.93703 23.8844 4.17354C23.579 4.41337 23.3881 4.7598 23.3466 5.14787C23.2586 5.93233 23.8246 6.64184 24.6081 6.73011C30.0108 7.33303 34.2102 11.5418 34.8177 16.9614C34.8991 17.6876 35.5082 18.2355 36.2352 18.2355C36.29 18.2355 36.3431 18.2322 36.3979 18.2256C36.778 18.1839 37.1166 17.9957 37.3556 17.6959C37.593 17.3961 37.7009 17.023 37.6577 16.6416C36.9008 9.87962 31.6674 4.63155 24.9268 3.8804" fill="white"/>
        <g filter="url(#filter0_d)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3862 22.1545C26.0347 28.8011 27.543 21.1117 31.7761 25.3419C35.8571 29.4218 38.2027 30.2392 33.0321 35.4084C32.3844 35.9289 28.2694 42.1909 13.8077 27.7333C-0.655787 13.2738 5.60265 9.15458 6.12329 8.50709C11.3065 3.32359 12.1098 5.68282 16.1908 9.76273C20.4239 13.9947 12.7378 15.5079 19.3862 22.1545Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d" x="0.333344" y="5.53384" width="39.6667" height="39.6667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        </defs>
        </svg>
      </button>

      <div class='modal-container'>
        <div class='modal-close modal-close-icon'></div>

        <form class='main-form'>
          <div class='form-header'>
            <h4>Оставьте заявку на консультацию и&nbsp;мы&nbsp;вам перезвоним</h4>
          </div>
          <div class='form-container'>
            <div class='form-field form-field--with-action'>
              <input type='tel' name='tel' minlength=10 value='+7 ' required />
              <div class="form-action"><input type='submit' value='Да, перезвоните мне'></div>
            </div>
            <div class='form-field'>
              <small>
                Отправляя данные через форму вы&nbsp;даете согласие на&nbsp;обработку своих <a href='#'>персональных данных</a>
              </small>
            </div>
          </div>
        </form>
      </div>`,
    giftForm: `
      <button class="openGiftModal button-gift hide">
        <label>Получи подарок!</label>
      </button>

      <div class='modal-container modal-container--gift-form'>
        <div class='modal-close modal-close-icon'></div>

        <form class='main-form'>
          <div class='form-header'>
            <h4>
              У нас для вас подарок — специальная цена
            </h4>
            <p>
              Оставьте заявку, чтобы получить персональное предложение и бесплатный выезд замерщика!
            </p>
          </div>
          <div class='form-container'>
            <div class='form-field form-field--with-action'>
              <input type='tel' name='tel' minlength=10 value='+7 ' required />
              <div class="form-action"><input type='submit' value='Да, перезвоните мне'></div>
            </div>
            <div class='form-field'>
              <small>
                Отправляя данные через форму вы&nbsp;даете согласие на&nbsp;обработку своих <a href='#'>персональных данных</a>
              </small>
            </div>
          </div>
        </form>
      </div>`,
  };

  let modal = document.createElement("div");
  modal.className = `modal-form ${type}`;
  const template = formTemplates[type]
    ? formTemplates[type]
    : formTemplates["modalForm"];
  modal.innerHTML = template;

  return modal;
};

const showModal = (modal) => {
  modal.classList.add("show");
};

const hideModal = (modal) => {
  modal.classList.remove("show");
};

const clickHandler = (modal) => (e) => {
  e.preventDefault();
  showModal(modal);
};

/**
 * ModalForm
 */
const initModalForm = () => {
  // let dataSitekey = "6LcmJoUaAAAAAE6Fqrm7hINOoW5dpS6xLlUfjWte";
  const formType = {
    type: "modalForm",
    textName: "Всплывающая форма",
  };

  const modal = renderModal(formType);
  formType.modal = modal;

  modal
    .querySelector(".main-form")
    .addEventListener("submit", submitForm(formType));
  const tel = modal.querySelector('input[type="tel"]');
  tel.addEventListener("input", telMask);
  document.body.append(modal);

  const close = modal.querySelector(".modal-close");
  close.addEventListener("click", (e) => {
    e.preventDefault();
    hideModal(modal);
  });

  window.addEventListener("click", (e) => {
    if (e.target == modal) {
      hideModal(modal);
    }
  });

  const links = document.querySelectorAll('[href="/forma-obratnoj-svyaz"]');
  links.forEach((link) => {
    link.addEventListener("click", clickHandler(modal));
  });
};

/**
 * CallbackForm
 */
const initCallbackForm = () => {
  const formType = {
    type: "callbackForm",
    textName: "Форма Callback",
  };

  const modal = renderModal(formType);
  formType.modal = modal;

  const openCallBackModal = modal.querySelector(".openCallBackModal");
  openCallBackModal.addEventListener("click", (e) => {
    modal.classList.toggle("active");
  });

  modal
    .querySelector(".main-form")
    .addEventListener("submit", submitForm(formType));
  const tel = modal.querySelector('input[type="tel"]');
  tel.addEventListener("input", telMask);
  document.body.append(modal);

  setTimeout(() => {
    modal.classList.add("active");
  }, 18 * 1000);

  const close = modal.querySelector(".modal-close");
  close.addEventListener("click", (e) => {
    e.preventDefault();

    ym && ym(75394450, "reachGoal", "non-f");

    modal.classList.toggle("active");

    // const formSended = localStorage.getItem("formSended");

    // if (!formSended) {
    //   setTimeout(() => {
    //     modal.classList.add("active");
    //   }, modalWaitingTime * 20);
    // }
  });
};

/**
 * CallbackForm
 */
const initGiftForm = () => {
  const formType = {
    type: "giftForm",
    textName: "Форма Gift",
  };

  const modal = renderModal(formType);
  formType.modal = modal;

  const openGiftModal = modal.querySelector(".openGiftModal");
  openGiftModal.addEventListener("click", (e) => {
    console.log("gift");

    ym && ym(75394450, "reachGoal", "cpc_podarok_lid");

    // if (!localStorage.getItem("giftClick")) {
    //   localStorage.setItem("giftClick", 1);
    // }

    modal.classList.toggle("active");
  });

  modal
    .querySelector(".main-form")
    .addEventListener("submit", submitForm(formType));
  const tel = modal.querySelector('input[type="tel"]');
  tel.addEventListener("input", telMask);
  document.body.append(modal);

  setTimeout(() => {
    const button = modal.querySelector(".button-gift.hide");
    button.classList.remove("hide");
  }, 20 * 1000);

  const close = modal.querySelector(".modal-close");
  close.addEventListener("click", (e) => {
    e.preventDefault();
    modal.classList.toggle("active");

    // const formSended = localStorage.getItem("formSended");

    // if (!formSended) {
    //   setTimeout(() => {
    //     modal.classList.add("active");
    //   }, modalWaitingTime * 20);
    // }
  });
};

/**
 * Main Blue Form
 */
const initMainBlueForm = () => {
  // let dataSitekey = "6LcmJoUaAAAAAE6Fqrm7hINOoW5dpS6xLlUfjWte";
  const formType = {
    type: "mainBlueForm",
    textName: "Форма с первого экрана",
  };

  const modal = document.querySelector("#blue-card-form");
  formType.modal = modal;

  modal.addEventListener("submit", submitForm(formType));
  const tel = modal.querySelector('input[type="tel"]');
  tel.addEventListener("input", telMask);
};

const initOpenForms = () => {
  const oForms = document.querySelectorAll("form.open-form");
  oForms.forEach((f) => {
    const formType = {
      type: f.id,
      textName: f.dataset.textname,
      modal: f,
    };

    f.addEventListener("submit", submitForm(formType));
    const tel = f.querySelector('input[type="tel"]');
    tel.addEventListener("input", telMask);
  });
};

const init = () => {
  // insertCss();
  initModalForm();
  initCallbackForm();
  initGiftForm();
  initMainBlueForm();

  initOpenForms();
};

const grecaptchaInitFunc = () => {
  if (typeof grecaptcha !== "undefined") {
    console.log("grecaptcha");
    console.log(grecaptcha);
    let dataSitekey = "6LcmJoUaAAAAAE6Fqrm7hINOoW5dpS6xLlUfjWte";

    grecaptcha.ready(function () {
      grecaptcha.render("recaptcha-container", {
        sitekey: dataSitekey,
      });
    });
  }
};

// window.onload = function () {
//   console.log("Modal-form enabled!");
//   init();

//   // grecaptchaInitFunc();
// };

document.addEventListener("DOMContentLoaded", () => {
  console.log("Modal-form enabled!");
  init();
});
